<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <OrderFilter
          :filterLabels="filterLabels"
          @switchFilter="switchFilter"
        />
        <div class="mb-2">
          <span class="h1 text-primary font-large-1">
            Total Order: {{ this.rows.length }}
          </span>
        </div>
        <b-card>
          <div>
            <b-button
              @click="ExportToExcel()"
              variant="outline-primary"
              class="btn-tour-skip my-1"
            >
              <span class="mr-25 align-middle">Extract Orders</span>
            </b-button>
          </div>
          <MyTable :columns="columns" :rows="rows" :isLoading="isLoading">
            <template v-slot="{ props }">
              <span v-if="props.column.field === 'id'">
                <b-button
                  variant="gradient-primary"
                  block
                  @click="goToOrderSummary(props.row.id)"
                >
                  {{ props.row.id }}
                </b-button>
              </span>
              <div
                v-if="props.column.field === 'pickup'"
                class="d-flex justify-content-center"
              >
                {{
                  !props.row.pickup_date
                    ? ""
                    : props.row.pickup_date.substr(0, 10)
                }}
              </div>
              <div
                v-if="props.column.field === 'delivery'"
                class="d-flex justify-content-center"
              >
                {{
                  !props.row.delivery_date
                    ? ""
                    : props.row.delivery_date.substr(0, 10)
                }}
              </div>
              <div
                v-if="props.column.field === 'sendername'"
                class="d-flex justify-content-center"
              >
                {{ props.row.sender.first_name }}
              </div>

              <div
                v-if="props.column.field === 'recipientname'"
                class="d-flex justify-content-center"
              >
                {{ props.row.recipient.first_name }}
              </div>
              <div
                v-if="props.column.field === 'origin'"
                class="d-flex justify-content-center"
              >
                {{ props.row.sender.complete_address }}
              </div>
              <div
                v-if="props.column.field === 'destination'"
                class="d-flex justify-content-center"
              >
                {{ props.row.recipient.complete_address }}
              </div>
              <span v-if="props.column.field === 'status'">
                <b-button
                  v-if="props.row.status === 'Active'"
                  block
                  variant="light-purple"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Assigned'"
                  block
                  variant="dark-purple"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'In Warehouse'"
                  block
                  variant="light-blue"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'In Transit'"
                  block
                  variant="yellow"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Out For Delivery'"
                  block
                  variant="purple"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Delivered'"
                  block
                  variant="turquoise"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Close Store'"
                  block
                  variant="maroon"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button v-else block variant="orange">
                  {{ props.row.status }}
                </b-button>
              </span>
              <div
                v-if="props.column.field === 'quantity'"
                class="d-flex justify-content-center align-items-center"
              >
                {{ !props.row.quantity ? "" : props.row.quantity }}
              </div>
              <div
                v-if="props.column.field === 'amount'"
                class="d-flex justify-content-center align-items-center"
              >
                {{
                  !props.row.amount
                    ? ""
                    : $helpers.formatTotal(props.row.amount)
                }}
              </div>
              <span v-if="props.column.field == 'action'">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  v-if="props.row.status != 'Cancelled'"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="
                      updateOrderStatus(props.row.id, props.row.originalIndex)
                    "
                  >
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Update Status</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="DownloadDO(props.row.id)">
                    <feather-icon icon="FileIcon" class="mr-50" />
                    <span>Download DO</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="cancelOrder(props.row.id, props.row.originalIndex)"
                  >
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Cancel Order</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </MyTable>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";
import OrderFilter from "./components/OrderFilter.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    MyTable,
    flatPickr,
    OrderFilter,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  async created() {
    this.getOrders();
  },

  methods: {
    getOrders() {
      this.isLoading = true;
      this.$http
        .get(`get_orders_by_type?order_type=Ondemand&filter=Active`)
        .then((response) => {
          if (response.data.data?.length > 0) {
            this.rows = [...response.data.data];
          }
          this.isLoading = false;
        });
    },
    async ExportToExcel() {
      const response = await this.$http.get(
        `orders_export/Ondemand/${this.dateFilter}/${this.filter}`,
        { responseType: "blob" }
      );
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", `${this.filter}OndemandOrder.xlsx`);
      document.body.appendChild(fileLink);

      fileLink.click();
    },

    goToOrderSummary(id) {
      this.$router.push({
        name: "order-summary",
        params: {
          id: id,
        },
      });
    },

    updateOrderStatus(id, index) {
      const reasons = this.optionsStatus;
      this.$swal({
        title: "Update Status",
        input: "select",
        inputOptions: reasons,
        inputPlaceholder: "Select Status",
        inputAttributes: {
          "aria-label": "Select Status",
        },
        inputValidator: (value) => !value && "Please give a value",
        allowOutsideClick: true,
        confirmButtonText: "SUBMIT",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          var updateStatus = {};
          updateStatus.order_id = id;
          updateStatus.status = result.value;
          this.$http
            .post(`update_order_status`, updateStatus)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.status == true) {
                  this.$swal({
                    title: "Status Delivery Updated !",
                    text: response.data.message,
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  this.rows[index].status = updateStatus.status;
                } else {
                  this.$swal({
                    title: "Status Delivery Error !",
                    text: response.data.message,
                    icon: "error",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                }
              }
            });
        }
      });
    },

    cancelOrder(id, index) {
      const reasons = this.optionsCancel;
      this.$swal({
        title: "Cancel Order",
        input: "select",
        inputOptions: reasons,
        inputPlaceholder: "Select Reason",
        inputAttributes: {
          "aria-label": "Select Reason",
        },
        inputValidator: (value) => !value && "Please give a value",
        allowOutsideClick: true,
        confirmButtonText: "SUBMIT",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          var orderCancel = {};
          orderCancel.order_id = id;
          orderCancel.status = "Cancelled";
          orderCancel.cancel_reason = result.value;
          this.$http
            .post(`distributor/update_status`, orderCancel)
            .then((response) => {
              if (response.status == 200) {
                if (response.data.status == true) {
                  this.$swal({
                    title: "Cancellation Accepted !",
                    icon: "success",
                    text: response.data.message,
                    confirmButtonText: "OK",
                    customClass: {
                      confirmButton: "btn btn-success",
                    },
                    buttonsStyling: false,
                  });
                  this.rows[index].status = "Cancelled";
                } else {
                  this.$swal({
                    title: "Cancellation Error !",
                    icon: "error",
                    text: response.data.message,
                    confirmButtonText: "Confirm",
                    customClass: {
                      confirmButton: "btn btn-warning",
                    },
                    buttonsStyling: false,
                  });
                }
              }
            });
        }
      });
    },

    DownloadDO(id) {
      window.open(`${this.$baseURL}/generate_golog_label/${id}`, "_blank");
    },

    async switchFilter(filter) {
      this.isLoading = true;
      this.filter = filter === "History" ? "Delivered" : filter;
      //Get Table Data every time filter changes
      await this.$http
        .get(`get_orders_by_type?order_type=Ondemand&filter=${this.filter}`)
        .then((response) => {
          if (response.status === 200 && response.data.data?.length > 0) {
            this.rows = [...response.data.data];
          } else {
            this.rows = [];
          }
          this.isLoading = false;
        });
    },
  },

  data() {
    return {
      dummyData: {},
      dateFilter: new Date().toJSON().slice(0, 10),
      type: "",
      isLoading: true,
      orderID: null,
      idx: null,
      filter: "Active",
      optionsStatus: {
        "In Warehouse": "In Warehouse",
        "Arrived At Branch": "Arrived At Branch",
        "Out For Delivery": "Out For Delivery",
        Delivered: "Delivered",
        "Close Store": "Close Store",
        "Second Delivery": "Second Delivery",
        "Assigned for Last Mile": "Assigned for Last Mile",
      },
      optionsCancel: {
        "Duplicate Order": "Duplicate Order",
        "Wrong Recipient's Address": "Wrong Recipient's Address",
        "Client Not Ready for Pick Up": "Client Not Ready for Pick Up",
        "Cancel Order": "Cancel Order",
        "IT Testing Order": "IT Testing Order",
      },
      filterLabels: [
        { label: "Active" },
        { label: "Assigned" },
        { label: "Scheduled" },
        { label: "History" },
        { label: "Cancelled" },
      ],
      columns: [
        {
          label: "Order ID",
          field: "id",
          tdClass: "align-middle",
        },
        {
          label: "Pickup Date/Time",
          field: "pickup",
          tdClass: "align-middle",
        },
        {
          label: "Delivery Date/Time",
          field: "delivery",
          tdClass: "align-middle",
        },
        {
          label: "Sender's Name",
          field: "sendername",
          tdClass: "align-middle",
        },
        {
          label: "Recipient's Name",
          field: "recipientname",
          tdClass: "align-middle",
        },
        {
          label: "Origin Address",
          field: "origin",
          tdClass: "align-middle",
        },
        {
          label: "Destination Address",
          field: "destination",
          tdClass: "align-middle",
        },
        {
          label: "Status",
          field: "status",
          tdClass: "align-middle",
        },
        {
          label: "Amount",
          field: "amount",
          tdClass: "align-middle",
        },
        {
          label: "Action",
          field: "action",
          tdClass: "align-middle",
        },
      ],
      rows: [],
      required,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
